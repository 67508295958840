export default {
  methods: {
    isFormatHISU(value) {
      const regex = /^([0-9]{2}):([0-5][0-9]):([0-5][0-9])(\.\d{1,3})?$/;
      return regex.test(value);
    },
    validateDecimalNumber(value) {
      let regexPattern = /^\d+(\.\d{1,3})?$/; // pattern cho số thập phân có tối đa hai chữ số sau dấu thập phân
      return regexPattern.test(value);
    }
  },
  filters: {
    convertHISUToSecond(hms) {
      if (!hms) return '';
      let a = hms.split(":");
      return +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    },
    convertSecondToHISU(second) {
      console.log(second);
      if (!second) return '';
      let array = second.split(".");
      let millisecond = "";
      if (array.length === 2) {
        millisecond = array[1];
      }
      return new Date(second * 1000).toISOString().substr(11, 8) + "." + millisecond;
    }
  }
}