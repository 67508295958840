<template>
  <div style="width: 100%">
    <v-row class="ml-1">
      <v-col cols="12" class="pb-0">
        <label class="text-h5">Question</label>
        <span class="d-block text-subtitle-1 red--text darken-1"
          >(Hãy nhập xong nội dung câu hỏi rồi mới nhấn nút đồng bộ)</span
        >
        <editor
          v-model.trim="contentSingleChoiceInParagraph"
          ref="refSingleChoiceInParagraph"
        ></editor>
      </v-col>
      <v-col cols="12" class="pt-2">
        <v-btn
          color="#05cdff"
          depressed
          outlined
          large
          @click="openDialogSingleChoiceInParagraph"
        >
          <v-icon left>mdi-plus</v-icon> Add Option
        </v-btn>
      </v-col>
      <v-col cols="12">
        <label class="text-h5">Chọn các đáp án đúng trong mỗi option:</label>
        <span class="d-block text-subtitle-1 red--text darken-1"
          >(Mỗi lần nhập hay sửa nội dung câu hỏi hãy nhấn núp đồng bộ để cập
          nhập lại các option)</span
        >
        <v-row class="mt-6">
          <template v-for="(data, i) in dataCorrectSingleChoiceParagraph">
            <v-col cols="4" :key="i" class="pt-0 pb-0">
              <label class="text-body-1">Đáp án option {{ i + 1 }}</label>
              <v-select
                dense
                v-model="data.correct"
                :items="data.option"
                filled
                placeholder="Chọn đáp án"
              ></v-select>
            </v-col>
          </template>
          <template v-if="dataCorrectSingleChoiceParagraph.length === 0">
            <div
              style="
                text-align: center;
                width: 100%;
                background-color: #f0f0f0;
                height: 80px;
              "
              class="mb-4 ml-2"
            >
              <p class="mt-8">No data</p>
            </div>
          </template>
        </v-row>
        <v-btn
          color="#05cdff"
          class="mt-2"
          depressed
          outlined
          large
          @click="syncDataCorrect"
        >
          <v-icon left>mdi-autorenew</v-icon> Đồng bộ option
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Editor from "@/components/ckeditor5/Editor";

export default {
  name: "SingleChoiceInParagraph",
  components: { Editor },
  data() {
    return {
      // contentSingleChoiceInParagraph: '',
    };
  },
  computed: {
    ...mapGetters({
      dataAddOptionSingleChoiceParagraph:
        "contentTestBuilderStore/dataAddOptionSingleChoiceParagraph",
    }),
    contentSingleChoiceInParagraph: {
      get() {
        return this.$store.state.contentTestBuilderStore
          .contentSingleChoiceInParagraph;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setContentSingleChoiceInParagraph",
          value
        );
      },
    },
    dialogAddOptionSingleChoiceParagraph: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogAddOptionSingleChoiceParagraph;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogAddOptionSingleChoiceParagraph", value);
      },
    },
    refSingleChoiceInParagraph: {
      get() {
        return this.$store.state.contentTestBuilderStore.refSingleChoiceInParagraph;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setRefSingleChoiceInParagraph", value);
      },
    },
    dataCorrectSingleChoiceParagraph: {
      get() {
        return this.$store.state.contentTestBuilderStore.dataCorrectSingleChoiceParagraph;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDataCorrectSingleChoiceParagraph", value);
      },
    },
  },
  watch: {
    dialogAddOptionSingleChoiceParagraph(val) {
      if (!val) {
        this.$refs.refSingleChoiceInParagraph.$refs.editorCustom.$_instance.editing.view.focus();
      }
    },
  },
  methods: {
    openDialogSingleChoiceInParagraph() {
      this.refSingleChoiceInParagraph = this.$refs.refSingleChoiceInParagraph.$refs.editorCustom;
      this.$store.commit("contentTestBuilderStore/setDataAddOptionSingleChoiceParagraph", [""]);
      this.dialogAddOptionSingleChoiceParagraph = true;
    },
    syncDataCorrect() {
      let contentSingleChoiceInParagraph = this.contentSingleChoiceInParagraph.trim();
      let arrPosBracesOpen = this.$utils.getAllStrPos("{{", contentSingleChoiceInParagraph);
      let arrPosBracesClose = this.$utils.getAllStrPos("}}", contentSingleChoiceInParagraph);
      if (arrPosBracesOpen.length !== arrPosBracesClose.length) {
        this.$toasted.error("Các dấu ngoặc giá trị (đóng, mở) trong câu hỏi chưa đủ !!", { theme: "toasted-primary", position: "top-right", duration: 4000 });
        return;
      }
      let dataPosBraces = arrPosBracesOpen.map(function (e, i) {
        return [e, arrPosBracesClose[i]];
      });
      this.dataCorrectSingleChoiceParagraph = dataPosBraces.map(function (
        position
      ) {
        let option_string = contentSingleChoiceInParagraph.substring(
          position[0] + 2,
          position[1]
        );
        let data_option = option_string.trim().split("//");
        return {
          correct: "",
          option: data_option,
        };
      });
    },
  },
};
</script>

<style scoped></style>
