<template>
  <div>
    <v-row>
      <v-col cols="12">
        <template v-if="url_video_config !== '' && url_video_config != null">
          <div style="text-align: center;">
            <vue-vimeo-player
                ref="player_vimeo"
                :videoUrl="url_video_config"
                @ready="onReady"
                @pause="pause()"
                :player-height="height"
                @seeked="setTextCopySelectedTimestamp"
            >
            </vue-vimeo-player>
            <div>
              <span class="text-subtitle-1">Selected timestamp (second):  </span>
              <span class="text-subtitle-1" v-if="!selected_timestamp_txt">NA</span>
              <span class="text-subtitle-1 green--text" v-if="selected_timestamp_txt">
                <span id="textSelectedTimestamp">{{selected_timestamp_txt | convertSecondToHISU }}</span>
                <v-icon @click="copySelectedTimestamp()" class="cursor-pointer" small>mdi-content-copy</v-icon>
              </span>
            </div>
          </div>
        </template>
      </v-col>
      <v-col cols="12"><span class="text-h5 font-weight-bold">Cấu hình timestamp của interactive quizz</span></v-col>
      <v-col class="pt-0 pb-0" cols="12">
        <v-row>
          <v-col cols="8">
            <p class="text-h6 mb-1 font-weight-medium">Mốc hiển thị câu hỏi (giờ:phút:giây.milli giây) <span class="red--text">(*)</span></p>
            <span class="text-subtitle-1">(Nhập mốc hiển thị câu hỏi trên vimeo)</span>
          </v-col>
          <v-col cols="4" class="">
            <v-text-field dense placeholder="giờ:phút:giây.milli giây" outlined v-model="display_timestamp_input" />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pt-0 pb-0" cols="12">
        <v-row>
          <v-col cols="8">
            <p class="text-h6 mb-1 font-weight-medium">Thời gian làm bài (giây) <span class="red--text">(*)</span></p>
            <span class="text-subtitle-1">(Nhập thời gian đếm ngược làm bài)</span>
          </v-col>
          <v-col cols="4">
            <v-text-field dense placeholder="giây" outlined v-model="time_limit_input" />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pt-0 pb-0" cols="12">
        <v-row>
          <v-col cols="8">
            <p class="text-h6 mb-1 font-weight-medium">Mốc hiển thị giải thích đáp án (giờ:phút:giây.milli giây)</p>
            <span class="text-subtitle-1">(Nhập mốc hiển thị giải thích đáp án trên video)</span>
          </v-col>
          <v-col cols="4">
            <v-text-field dense placeholder="giờ:phút:giây.milli giây" outlined v-model="display_explanation_input" />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pt-0 pb-0" cols="12">
        <v-row>
          <v-col cols="8">
            <p class="text-h6 mb-1 font-weight-medium">Mốc hiển thị nội dung học tiếp theo (giờ:phút:giây.milli giây) <span class="red--text">(*)</span></p>
            <span class="text-subtitle-1">(Nhập mốc hiển thị nội dung học tiếp theo)</span>
          </v-col>
          <v-col cols="4">
            <v-text-field dense placeholder="giờ:phút:giây.milli giây" outlined v-model="continue_lesson_timestamp_input" />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pt-0 pb-0" cols="12">
        <v-row>
          <v-col cols="8">
            <p class="text-h6 mb-1 font-weight-medium">Thời gian chờ (giây) <span class="red--text">(*)</span></p>
            <span class="text-subtitle-1">(Nhập thời gian chờ học viên phản hồi với màn hình đáp án)</span>
          </v-col>
          <v-col cols="4">
<!--            <span class="text-subtitle-1">( Display: {{convertSecondToHMS(waiting_time_input)}} )</span>-->
            <v-text-field dense placeholder="giây" outlined v-model="waiting_time_input" />
          </v-col>
        </v-row>
      </v-col>
<!--      <v-col cols="12"><span class="text-h5 font-weight-bold">Cấu hình mức điểm</span></v-col>-->
<!--      <v-col cols="12">-->
<!--        <v-row>-->
<!--          <v-col cols="8">-->
<!--            <p class="text-h6 mb-1 font-weight-medium">Mức điểm chấp nhận được (%):</p>-->
<!--            <span class="text-subtitle-1">(Số đấp án học viển lựa chọn chính xác / Tổng số đáp án trong bài)</span>-->
<!--          </v-col>-->
<!--          <v-col cols="4">-->
<!--            <v-text-field dense outlined placeholder="Nhập %" v-model="acceptable_score_input" />-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-col>-->
      <v-col cols="12">
        <span class="text-h5 font-weight-bold">
          Chú ý: khi thêm nhiều question trong exercise, hệ thống sẽ hiển thị lần lượt các question trong cùng một mốc thời gian
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import { vueVimeoPlayer } from "vue-vimeo-player";
import filterTimestamp from "./filterTimestamp";

export default {
  name: "ConfigVimeo",
  mixins: [filterTimestamp],
  components: {
    vueVimeoPlayer,
  },
  props: {
    url_vimeo: {
      type: String,
      default: '',
    },
    dialogExercise: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      height: 350,
      options: {
        muted: true,
        autoplay: true,
      },
      playerReady: false,
      // url_vimeo: "",
      vimeo_video_id: "",
      selected_timestamp_txt: '',
    }
  },
  computed: {
    ...mapState({
      type_form_exercise: (state) => state.contentTestBuilderStore.type_form_exercise,
      section_id: (state) => state.contentTestBuilderStore.section_id,
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
      indexExercise: (state) => state.contentTestBuilderStore.indexExercise,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    // dialogExercise: {
    //   get() {
    //     return this.$store.state.contentTestBuilderStore.dialogExercise;
    //   }
    // },
    display_timestamp_input: {
      get() {
        return this.$store.state.interactiveVideoStore.display_timestamp;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setDisplayTimestamp", value);
      },
    },
    display_explanation_input: {
      get() {
        return this.$store.state.interactiveVideoStore.display_explanation;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setDisplayExplanation", value);
      },
    },
    continue_lesson_timestamp_input: {
      get() {
        return this.$store.state.interactiveVideoStore.continue_lesson_timestamp;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setContinueLessonTimestamp", value);
      },
    },
    waiting_time_input: {
      get() {
        return this.$store.state.interactiveVideoStore.waiting_time;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setWaitingTime", value);
      },
    },
    time_limit_input: {
      get() {
        return this.$store.state.interactiveVideoStore.time_limit;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setTimeLimit", value);
      },
    },
    acceptable_score_input: {
      get() {
        return this.$store.state.interactiveVideoStore.acceptable_score;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setAcceptableScore", value);
      },
    },
    toggle_interactive_video: {
      get() {
        return this.$store.state.interactiveVideoStore.toggle_interactive_video;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setToggleInteractiveVideo", value);
      },
    },
    url_video_config: {
      get() {
        return this.$store.state.interactiveVideoStore.url_video_config;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setUrlVideoConfig", value);
      },
    },

  },
  watch: {
    dialogExercise(val) {
      if (!val) {
        this.display_timestamp_input = "";
        this.display_explanation_input = "";
        this.continue_lesson_timestamp_input = "";
        this.waiting_time_input = "";
        this.time_limit_input = "";
        this.acceptable_score_input = "";
        this.selected_timestamp_txt = "";
        this.url_video_config = "";
        // this.pause();
      }
    }
  },
  methods: {
    setDataComponent() {
      // let section = this.skills[this.indexSkill].sections[this.indexSection];

      // if (section.element_type === 'Video') {
        // this.url_vimeo = section.element_data;
        // this.vimeo_video_id = section.element_id;
      // }

      // if (this.type_form_exercise === 'update') {
      //   let exercise = section.exercises[this.indexExercise];
      //   if (exercise.interactive_config) {
      //     let data_config = exercise.interactive_config;
      //     this.display_timestamp_input = data_config.display_timestamp;
      //     this.display_explanation_input = data_config.display_explanation;
      //     this.continue_lesson_timestamp_input = data_config.continue_lesson_timestamp;
      //     this.waiting_time_input = data_config.waiting_time;
      //     this.time_limit_input = data_config.time_limit;
      //     this.acceptable_score_input = data_config.acceptable_score;
      //   }
      // }
    },
    onReady() {
      this.playerReady = true;
    },
    play() {
      this.$refs.player_vimeo.play();
    },
    async pause() {
      this.$refs.player_vimeo.pause();
      let currentTime = await this.$refs.player_vimeo.player.getCurrentTime();
      this.selected_timestamp_txt = currentTime.toString();
    },

    setTextCopySelectedTimestamp($event) {
      console.log($event)
      this.selected_timestamp_txt = $event.seconds.toString();
    },
    copySelectedTimestamp() {
      navigator.clipboard.writeText(this.$options.filters.convertSecondToHISU(this.selected_timestamp_txt));
      this.$toasted.info("Copied", {theme: "toasted-primary", position: "top-right", duration: 700});
    }
  },
}
</script>

<style scoped>

</style>