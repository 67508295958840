<template>
  <div style="width: 100%">
    <ckeditor
        @ready="(editor) => eventReadyEditor(editor, this)"
        @change="(event) => console.log(event)"
        :editor="editor"
        :config="editorConfig"
        v-model="inputVal"
        :disabled="disabled"
        class="editorOnlyImage"
    ></ckeditor>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUploadPlugin from "@ckeditor/ckeditor5-image/src/imageupload";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import DeletePlugin from "@ckeditor/ckeditor5-typing/src/delete";

import Ls from "@/core/services/jwt.service.js";
const TOKEN_PREP = Ls.getToken();

export default {
  name: "EditorOnlyImage",
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    minHeight: {
      type: [Number, String],
      default: 50,
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          SimpleUploadAdapter,
          Image,
          ImageResize,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
          DeletePlugin,
        ],
        toolbar: {
          items: ["imageUpload",],
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          // process.env.VUE_APP_BASE_URL
          // uploadUrl: 'https://prep.vn.test/api/prep-app/element/upload-image-ckeditor?is-option-exercise=1',
          uploadUrl: process.env.VUE_APP_BASE_URL + "prep-app/element/upload-image-ckeditor?is-option-exercise=1",

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            // 'X-CSRF-TOKEN': 'CSRF-Token',
            Authorization: "Bearer " + TOKEN_PREP,
          },
        },
        image: {
          toolbar: [
            "imageStyle:full",
            "imageStyle:side",
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
          ],
          styles: [
            // This option is equal to the situation when no style is applied.
            "full",
            // This represents a side image.
            "side",
            // This style represents an image aligned to the left.
            "alignLeft",
            // This style represents a centered image.
            "alignCenter",
            // This style represents an image aligned to the right.,
            "alignRight",
          ],
        },
      },
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    eventReadyEditor(editor, vm) {
      this.customHeight(editor, vm.minHeight);
    },
    customHeight: (editor, minHeight) => {
      let cpEditor = editor;
      editor.editing.view.change((writer) => {
        writer.setStyle( 'min-height', minHeight+'px', cpEditor.editing.view.document.getRoot() );
      });
    }
  }
}
</script>

<style scoped>

</style>