<template>
  <div style="width: 100%" class="mb-6">
    <p class="black--text font-weight-bold text-h6 ml-2">Thông tin hội thoại</p>
    <template v-for="(question, iQues) in dataMockConversationQuestion">
      <v-row class="mb-4" :key="iQues">
        <v-col cols="2" class="pr-1 pb-0 d-flex">
          <span class="mt-1 text-h6 font-weight-bold mr-1">#{{iQues+1}}</span>
          <v-select
            :items="dataCharacterConfig"
            v-model="question.character_id"
            item-value="id"
            label="Nhân vật"
            outlined
          >
            <template v-slot:item="{item}">
              <div class="pt-1 pb-1">
                <v-list-item-avatar v-if="getObjCharacter(item.id)">
                  <img
                      :src="getObjCharacter(item.id).avatar"
                      :alt="getObjCharacter(item.id).name"
                  >
                </v-list-item-avatar>
                <span class=" ml-2 black--text">{{item.name}} - {{item.gender === 'male' ? 'Nam' : 'Nữ'}} - {{item.job}}</span>
              </div>
            </template>
            <template v-slot:selection="{ item, index }">
              <template v-if="item">
                <div class="position-absolute left-0 right-0 black--text selection-figure-span" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                  <v-list-item-avatar class="ml-0 mr-0" v-if="getObjCharacter(item.id)">
                    <img
                        :src="getObjCharacter(item.id).avatar"
                        :alt="getObjCharacter(item.id).name"
                    >
                  </v-list-item-avatar>
                  <span>
                    {{item.name}} - {{item.gender === 'male' ? 'Nam' : 'Nữ'}} - {{item.job}}
                  </span>
                </div>
              </template>
              <template v-else>
                <span class="grey--text">Chọn nhân vật</span>
              </template>
            </template>
          </v-select>
        </v-col>

        <v-col cols="4" class="pl-0 pr-1 pb-0">
          <v-textarea
            v-model="question.script"
            label="Lời thoại"
            placeholder="Nhập lời thoại"
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
        <v-col cols="4" class="pl-0 pr-1 pb-0">
          <v-textarea
            v-model="question.translate_script"
            label="Dịch lời thoại"
            placeholder="Nhập dịch lời thoại"
            outlined
            rows="1"
          ></v-textarea>
        </v-col>
        <v-col cols="2" class="pl-0 pb-0">
          <div style="width: 100%" class="d-flex">
              <v-file-input
                :key="iQues"
                :value="question.audio_file"
                @change="(e) => onAudioFileOptionChange(e, iQues)"
                outlined
                class="mt-0"
                prepend-icon="mdi-music-note"
                accept="audio/*"
                :label="getLabelAudioFromObjQuestion(question)"
              >
                <template v-slot:selection>
                  <div class="position-absolute" style="width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                    <span>
                      {{ getTextSelectionAudioFromObjQuestion(question) }}
                    </span>
                  </div>
                </template>
              </v-file-input>
              <v-icon class="pb-5" large dense @click="deleteItemMockConversation(question)">mdi-delete</v-icon>
          </div>
        </v-col>
        <v-col cols="12" class="pt-0" v-if="question.audio_data">
          <vuetify-audio
            :file="question.audio_data"
            color="primary" class=""
          />
        </v-col>
      </v-row>
    </template>

    <div style="width: 180px" class="d-flex cursor-pointer" @click="addItemMockConversation()">
      <v-btn outlined icon color="sliver">
        <v-icon color="sliver">mdi-plus</v-icon>
      </v-btn>
      <span class="text-h6 mt-1 ml-1">Thêm lời thoại</span>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import VuetifyAudio from "../../../marking-management/Audio";
import ApiService from "@/service/api.service";

export default {
  name: "MockConversation",
  components: {VuetifyAudio},
  data() {
    return {
      character_id: null,
    }
  },
  computed: {
    ...mapState({
      dataCharacterConfig: (state) => state.exerciseConfigStore.characters,
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dataMockConversationQuestion: {
      get() {
        return this.$store.state.exerciseConfigStore.dataMockConversationQuestion;
      },
      set(value) {
        this.$store.commit("exerciseConfigStore/setDataMockConversationQuestion", value);
      },
    },
  },
  created() {},
  methods: {
    getNameFileByUrl(url) {
      if (!url) return "";
      return url.split("/").pop();
    },
    getLabelAudioFromObjQuestion(objQuestion) {
      if (objQuestion.audio_file &&  objQuestion.audio_data) {
        return 'Nhập audio lời thoại';
      }
      if (objQuestion.audio_file) {
        return objQuestion.audio_file.name;
      }
      if (objQuestion.audio_data){
        return this.getNameFileByUrl(objQuestion.audio_data);
      }

      return 'Nhập audio lời thoại';
    },
    getTextSelectionAudioFromObjQuestion(objQuestion) {
      if (objQuestion.audio_file) {
        return objQuestion.audio_file.name;
      }
      if (objQuestion.audio_data){
        return this.getNameFileByUrl(objQuestion.audio_data);
      }

      return '';
    },
    messError(mess) {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    messSuccess(mess) {
      this.$toasted.success(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    addItemMockConversation() {
      // get offset largest
      let offsetLargest = 0;
      this.dataMockConversationQuestion.forEach((question) => {
        if (question.offset > offsetLargest) {
          offsetLargest = question.offset;
        }
      });
      this.dataMockConversationQuestion.push({
        question_id: null,
        offset: offsetLargest + 1,
        character_id: null,
        script: "",
        translate_script: "",
        audio_file: null,
        audio_data: null,
        audio_transcript: null,
      });

      this.resetOffsetMockConversation();
    },
    deleteItemMockConversation(question) {
      if (this.dataMockConversationQuestion.length <= 1) {
        this.messError("Phải có ít nhất 1 lời thoại");
        return;
      }
      const index = this.dataMockConversationQuestion.indexOf(question);
      if (index > -1) {
        this.dataMockConversationQuestion.splice(index, 1);
      }

      this.resetOffsetMockConversation();
    },
    resetOffsetMockConversation() {
      this.dataMockConversationQuestion.forEach((question, index) => {
        question.offset = index + 1;
      });
    },
    async onAudioFileOptionChange(file, indexQuestion) {
      console.log(file, indexQuestion);
      let vm = this;
      if (file === undefined || file == null) {
        vm.resetFileMockConversationByIndex(indexQuestion);
        return;
      } else {
        let extFile = this.$utils.getExtensionByFile(file);
        if (!["mp3", "wav", "ogg",].includes(extFile)) {
          this.messError("Script #" + (indexQuestion + 1) + ". File audio không đúng định dạng, chỉ bao gồm mp3, wav, ogg");
          vm.resetFileMockConversationByIndex(indexQuestion);
          return;
        }

        if (file.size > 209716) {
          vm.messError("File audio của script #" + (indexQuestion + 1) + " không được lớn hơn 200kb (0.2mb)");
          vm.resetFileMockConversationByIndex(indexQuestion);
          return;
        }
      }

      // let reader = new FileReader();
      // reader.onload = (e) => {
      //   vm.dataMockConversationQuestion[indexQuestion].audio_data = e.target.result;
      //   vm.dataMockConversationQuestion[indexQuestion].audio_file = file;
      // };
      // reader.readAsDataURL(file);
      let resultDataAudio = await this.getAudioUrlByFile(file);
      this.dataMockConversationQuestion[indexQuestion].audio_data = resultDataAudio.url;
      this.dataMockConversationQuestion[indexQuestion].audio_transcript = resultDataAudio.transcript;
      this.dataMockConversationQuestion[indexQuestion].audio_file = null;
    },
    resetFileMockConversationByIndex(index) {
      let vm = this;
      setTimeout(() => {
        vm.dataMockConversationQuestion[index].audio_data = null;
        vm.dataMockConversationQuestion[index].audio_file = null;
        vm.dataMockConversationQuestion[index].audio_transcript = null;
      }, 200);
    },
    getObjCharacter(character_id) {
      return this.dataCharacterConfig.find((item) => item.id === character_id);
    },
    async getAudioUrlByFile(file) {
      let vm = this;
      try {
        vm.is_call_api = true;
        let formData = new FormData();
        formData.append("file", file);
        let res = await ApiService.post("prep-app/test/draft/question/transcript-audio-by-deep-gram", formData);
        if (res.status === 200) {
          console.log(res.data)
          vm.messSuccess("Upload file audio thành công");
          vm.is_call_api = false;
          return {
            url: res.data.url,
            transcript: res.data.transcript,
          }
        }
      } catch (error) {
        vm.messError('Không thể upload được file. Vui lòng liên hệ bộ phận kỹ thuật');
        console.log(error);
        vm.is_call_api = false;
        return {url: null, transcript: null,};
      }
    },
  },
}
</script>

<style>
.selection-figure-span ~ input{
  display: none;
}
.selection-figure-span + input{
  display: none;
}
</style>